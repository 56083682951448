import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { EmployeeListItem, EmployeeListSearchParams, EmployeesState, ViewType } from '../employees.interface';
import { PaginatedData } from '../../shared/model/table';
import {
  ChangeExcludedFromSignaturesAction,
  ClearEmployeesParamsAction,
  GetEmployeesAction,
  SetEmployeesParamsAction,
  SyncGoogleEmployeesAction
} from '../employees.actions';
import { getEmployeeListSelector, isSyncInProgressSelector } from '../employees.reducer';
import { Signature } from '../../signatures/signatures.interface';
import { GetSignaturesAction } from '../../signatures/signatures.actions';
import { getSignaturesSelector } from '../../signatures/signatures.reducer';
import { ReportsService } from 'src/app/shared/reports/reports.service';
import { EmployeesService } from '../employees.service';
import { SettingsService } from '../../settings/settings.service';
import { AuthService } from '../../auth/auth.service';
import { UserService } from '../../users/users.service';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.scss']
})
export class EmployeeListComponent implements OnInit {

  public displayedColumns = ['fullName', 'email', 'type', 'emptyFieldsCount', 'createdAt', 'modifiedAt', 'signatureTemplateId', 'signatureAccepted', 'excludedFromSignatures', 'id'];
  public dataSource: MatTableDataSource<EmployeeListItem>;
  public pageSizeOptions = [10, 20, 100];
  public filters: EmployeeListSearchParams;
  public phraseControl = new UntypedFormControl('');
  private phraseControlSubscription: Subscription;
  public uniqueGroupEmails: string[] = [];
  public groupMembersMap: Map<string, string[]> = new Map();
  @ViewChild(MatPaginator) paginator: MatPaginator = {} as MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public signatures$: Observable<Signature[]>;
  public signatureNamePerId$: Observable<Map<string, string>>;
  public isSyncInProgress$: Observable<boolean>;
  public defaultSignatureFilter?: string;

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private reportsService: ReportsService,
    private employeesService: EmployeesService,
    private settingsService: SettingsService,
    public authService: AuthService,
    private userService: UserService
  ) {
  }

  ngOnInit(): void {
    this.clearFilters();
    this.store.dispatch(new GetSignaturesAction());
    this.dataSource = new MatTableDataSource([]);

    this.store.select(getEmployeeListSelector).subscribe((state: EmployeesState) => {
      const { employees, params } = state;
      this.filters = { ...params };
      this.updateDataSource(employees);
      this.updatePaginator(employees, params);
      this.loadGroupEmails();

      if (!this.filters.viewType) {
        this.filters.viewType = 'EMPLOYEES';
      }
    });

    this.defaultSignatureFilter = this.route.snapshot.queryParams.signatureTemplateId;
    const isAliasFilter = this.route.snapshot.queryParams.aliases === 'true';

    this.filters.viewType = isAliasFilter ? 'ALIASES' : this.filters.viewType || 'EMPLOYEES';

    if (this.defaultSignatureFilter) {
      this.filters.signatureTemplateId = this.defaultSignatureFilter;
      this.store.dispatch(new SetEmployeesParamsAction(this.filters));
    }

    this.changeDataOnSearchPhraseChanges();
    this.signatures$ = this.store.select(getSignaturesSelector);
    this.isSyncInProgress$ = this.store.select(isSyncInProgressSelector);
    this.signatureNamePerId$ = this.store.select(getSignaturesSelector)
      .pipe(
        map((signatures) => new Map(signatures?.map(s => ([s.id, s.name])))),
        startWith(new Map()),
      );
  }

  changePage = (event: PageEvent) => {
    this.filters.pager = {
      limit: event.pageSize,
      page: event.pageIndex
    };
    this.store.dispatch(new SetEmployeesParamsAction(this.filters));
  };

  sortChange = ({active, direction}) => {
    this.filters.order = direction.toUpperCase() || undefined;
    this.filters.sortBy = active;
    this.resetPager();
    this.store.dispatch(new SetEmployeesParamsAction(this.filters));
  };

  changeExcludedFromSignatures = (checked, employee) => {
    this.store.dispatch(new ChangeExcludedFromSignaturesAction({id: employee.id, excludedFromSignatures: checked}));
  };

  private resetPager = () => this.filters.pager = {...this.filters.pager, page: 0};

  private updateDataSource = (employeeList: PaginatedData<EmployeeListItem>) => this.dataSource.data = employeeList.data;

  private updatePaginator = (employeeList: PaginatedData<EmployeeListItem>, params: EmployeeListSearchParams) => {
    this.paginator.length = employeeList.total;
    if (params && params.pager) {
      const {limit, page} = params.pager;
      this.paginator.pageIndex = page;
      this.paginator.pageSize = limit;
    }
  };

  private loadGroupEmails(): void {
    this.userService.groups().subscribe(groups => {
      this.uniqueGroupEmails = groups.map(group => group.email);
    });
  }

  private changeDataOnSearchPhraseChanges = () => {
    this.phraseControlSubscription = this.phraseControl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(phrase => this.addFilter('phrase', phrase));
  };

  public addFilter = <T extends keyof EmployeeListSearchParams>(type: T, value: EmployeeListSearchParams[T]) => {
    this.filters[type] = value;
    this.resetPager();
    this.store.dispatch(new SetEmployeesParamsAction(this.filters));
  };

  syncGoogle() {
    this.store.dispatch(new SyncGoogleEmployeesAction());
  }

  filterBySignature(signatureTemplateId?: string) {
    this.filters.signatureTemplateId = signatureTemplateId;
    this.store.dispatch(new SetEmployeesParamsAction(this.filters));
  }

  clearFilters() {
    this.store.dispatch(new ClearEmployeesParamsAction());
  }

  toggleView() {
    this.addFilter('viewType', this.filters.viewType);
  }

  generateReport = async () => {
    const [employeeList, aliasList] = await Promise.all([
      this.employeesService.getAllEmployees().toPromise(),
      this.employeesService.getEmployees({ pager: { limit: 1000, page: 0 } }, true).toPromise()
    ]);
    const employeeSettings = await this.settingsService.getEmployeeSettings().pipe(map(res => res.map(e => e.name))).toPromise();

    const combinedList = [...employeeList, ...aliasList.data];

    this.reportsService.generateEmployeesReport(combinedList, employeeSettings);
  };

}
