<mat-toolbar class="header">
  <span>
    {{ ('menu.type.' + filters.viewType) | translate }}: {{paginator.length}}
  </span>

  <div class="actions">
    <button mat-raised-button [routerLink]="['request']">{{'employee.sendRequest.label' | translate}}</button>
    <button mat-raised-button (click)="generateReport()">{{ 'client.downloadReport' | translate }}</button>
    <button mat-raised-button (click)="syncGoogle()" [disabled]="isSyncInProgress$ | async">
      <div class="spinner-border spinner-border-sm" role="status" *ngIf="isSyncInProgress$ | async"></div>
      {{'employee.sync-google' | translate}}
    </button>
  </div>
</mat-toolbar>

<div class="employees-wrapper">

  <div class="table-search">
    <mat-form-field>
      <input matInput
             [formControl]="phraseControl"
             placeholder="{{('employee.search') | translate}}"/>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{'employee.filterBySignature' | translate}}</mat-label>

      <mat-select (selectionChange)="filterBySignature($event.value)" [value]="defaultSignatureFilter">
        <mat-option [value]="undefined">-</mat-option>
        <mat-option *ngFor="let signature of signatures$ | async" [value]="signature.id">{{signature.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{'employee.filterByHasSignature.label' | translate}}</mat-label>

      <mat-select (selectionChange)="addFilter('signatureInstalled', $event.value)">
        <mat-option [value]="undefined">{{'employee.filterByHasSignature.ALL' | translate}}</mat-option>
        <mat-option value="ONLY_ENABLED"> {{'employee.filterByHasSignature.ONLY_ENABLED' | translate}}</mat-option>
        <mat-option value="ONLY_DISABLED"> {{'employee.filterByHasSignature.ONLY_DISABLED' | translate}}</mat-option>
      </mat-select>
    </mat-form-field>


    <mat-form-field>
      <mat-label>{{'employee.filterByExcluded.label' | translate}}</mat-label>

      <mat-select (selectionChange)="addFilter('excludedFromSignatures', $event.value)">
        <mat-option [value]="undefined">{{'employee.filterByExcluded.ALL' | translate}}</mat-option>
        <mat-option value="ONLY_ENABLED"> {{'employee.filterByExcluded.ONLY_ENABLED' | translate}}</mat-option>
        <mat-option value="ONLY_DISABLED"> {{'employee.filterByExcluded.ONLY_DISABLED' | translate}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{'employee.filterByGroup.label' | translate}}</mat-label>
      <mat-select (selectionChange)="addFilter('groupEmail', $event.value)">
        <mat-option [value]="undefined">{{'employee.filterByGroup.ALL' | translate}}</mat-option>
        <mat-option *ngFor="let groupEmail of uniqueGroupEmails" [value]="groupEmail">{{ groupEmail }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'employee.viewSelection.label' | translate }}</mat-label>
      <mat-select [(value)]="filters.viewType" (selectionChange)="toggleView()">
        <mat-option value="EMPLOYEES">{{ 'employee.viewSelection.viewEmployees' | translate }}</mat-option>
        <mat-option value="ALIASES">{{ 'employee.viewSelection.viewAliases' | translate }}</mat-option>
        <mat-option value="BOTH">{{ 'employee.viewSelection.viewBoth' | translate }}</mat-option>
      </mat-select>
    </mat-form-field>

  </div>

  <mat-table
    (matSortChange)="sortChange($event)"
    matSort
    [dataSource]="dataSource"
  >
    <ng-container matColumnDef="fullName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{'employee.fullName' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element" class="company-wrapper">
        <div *ngIf="element.fullName">{{ element.fullName }}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{'employee.email' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div *ngIf="element.email">{{ element.email }}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef>{{'employee.type' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div>
          {{ element.isAlias ? ('employee.alias' | translate) : ('employee.employee' | translate) }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="emptyFieldsCount">
      <mat-header-cell *matHeaderCellDef>{{'employee.emptyFieldsCount' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div *ngIf="element.emptyFieldsCount">{{ element.emptyFieldsCount }}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{'employee.createdAt' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div *ngIf="element.createdAt">{{ element.createdAt | localizedDate:'medium' }}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="modifiedAt">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{'employee.modifiedAt' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div *ngIf="element.modifiedAt">{{ element.modifiedAt | localizedDate:'medium' }}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="signatureTemplateId">
      <mat-header-cell *matHeaderCellDef>{{'employee.signatureTemplateIdColumn' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <a class="chip ellipsis" *ngIf="element.signatureTemplateId"
           [title]="(signatureNamePerId$ | async)?.get(element.signatureTemplateId) "
           [routerLink]="['../signatures', element.signatureTemplateId]">{{ (signatureNamePerId$ | async)?.get(element.signatureTemplateId) }}</a>

        <span *ngIf="!element.signatureTemplateId">-</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="signatureAccepted">
      <mat-header-cell *matHeaderCellDef>{{'employee.signatureAccepted' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-checkbox
          *ngIf="!element.isAlias"
          [disabled]="true"
          [checked]="element.signatureAccepted"
        ></mat-checkbox>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="excludedFromSignatures">
      <mat-header-cell *matHeaderCellDef>{{'employee.excludedFromSignatures' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-checkbox
          *ngIf="!element.isAlias"
          [disabled]="!element.canBeExcludedFromSignatures"
          (change)="$event ? changeExcludedFromSignatures($event.checked, element) : null"
          [checked]="element.excludedFromSignatures"
          [matTooltip]="element.canBeExcludedFromSignatures ? undefined : 'employee.cannotBeExcludedFromSignatures' | translate"
        ></mat-checkbox>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef sticky></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <a *ngIf="!element.isAlias" [routerLink]="[element.id, 'edit']">
          <mat-icon>edit</mat-icon>
        </a>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'alias-row': row.isAlias}"></mat-row>
  </mat-table>

  <mat-paginator
    [pageSizeOptions]="pageSizeOptions"
    [length]="paginator.length"
    [pageSize]="paginator.pageSize"
    (page)="changePage($event)"
  ></mat-paginator>

  <app-employee-data-import/>


</div>
