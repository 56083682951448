import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UserService} from '../users/users.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {Group} from '../users/user';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit {

  displayedColumns = ['expand', 'name', 'email', 'directMembersCount', 'assign'];
  dataSource: MatTableDataSource<Group>;
  expandedGroupId: string | null = null;
  members: string[] = [];
  @Input() updating ? = false;
  @Output() clicked = new EventEmitter<string>();
  @Output() clickedAll = new EventEmitter<{ group: string, alias: string[] }>();
  @ViewChild(MatSort) sort: MatSort;

  constructor(private userService: UserService) {
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource([]);
    this.loadData();
  }

  loadData() {
    this.userService.groups().subscribe(data => {
      this.dataSource.data = data;
      this.dataSource.sort = this.sort;
    });
  }

  assign(group: string) {
    return this.clicked.emit(group);
  }

  assignToAll(group: string, alias: string) {
    return this.clickedAll.emit({ group, alias: [alias] });
}

  toggleMembers(groupId: string) {
    if (this.expandedGroupId === groupId) {
      this.expandedGroupId = null;
      this.members = [];
    } else {
      this.expandedGroupId = groupId;
      const selectedGroup = this.dataSource.data.find(group => group.id === groupId);
      this.members = selectedGroup ? selectedGroup.members : [];
    }
  }
}
