<mat-table matSort [dataSource]="dataSource">
    <ng-container matColumnDef="expand">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element" class="mat-column-expand" [ngClass]="{'align-top': expandedGroupId === element.id, 'expand-margin': expandedGroupId === element.id}">
            <button mat-icon-button (click)="toggleMembers(element.id); $event.stopPropagation()">
                <mat-icon>{{ expandedGroupId === element.id ? 'expand_less' : 'expand_more' }}</mat-icon>
            </button>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{'group.name' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let element" [ngClass]="{'align-top': expandedGroupId === element.id}">
            <div class="cell-content">
            <div>{{ element.name }}</div>
            </div>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{'group.email' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let element" [ngClass]="{'align-top': expandedGroupId === element.id}">
            <div class="cell-content">
                <div>{{ element.email }}</div>
            </div>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="directMembersCount">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{'group.count' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let element">
            <div *ngIf="expandedGroupId !== element.id" class="cell-content">{{ element.directMembersCount }}</div>
            <div *ngIf="expandedGroupId === element.id">
                <mat-list>
                    <mat-list-item *ngFor="let member of element.members" class="group-members">
                        {{ member }}
                    </mat-list-item>
                </mat-list>
            </div>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="assign">
        <mat-header-cell *matHeaderCellDef>{{'footers.set' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let element" [ngClass]="{'align-top': expandedGroupId === element.id}">
            <div class="group-actions">
                <button mat-raised-button (click)="assign(element.id); $event.stopPropagation()" [disabled]="updating">
                    {{(updating ? 'footers.setting' : 'footers.set') | translate}}
                </button>
                <button mat-raised-button (click)="assignToAll(element.id, element.email); $event.stopPropagation()" [disabled]="updating">
                    {{(updating ? 'footers.setting' : 'footers.setAllWithAlias') | translate}}
                </button>
            </div>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="toggleMembers(row.id)"></mat-row>
</mat-table>
