import {Component, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Employee} from '../employees.interface';
import {ActivatedRoute} from '@angular/router';
import {Store} from '@ngrx/store';
import {GetCurrentUserAction, UpdateEmployeeAndInstallSignatureAction} from '../employees.actions';
import {validateNonRequiredPhoneNumber} from '../../shared/validators/phone-number.validator';
import {getCurrentEmployeeSelector} from '../employees.reducer';
import {ConfirmationDialogComponent} from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {parseFooterTemplate} from '../../shared/utils/footers.util';
import { GetAdvancedSettingsAction, GetEmployeeSettingsAction } from '../../settings/settings.actions';
import { getAdvancedSettingsSelector, getEmployeeSettingsSelector } from '../../settings/settings.reducer';
import { AdvancedSettings, EmployeeSetting } from '../../settings/settings.interface';
import {validateUrl} from '../../shared/validators/url.validator';
import {AuthService} from 'src/app/auth/auth.service';
import {StartCheckingForInstallationInProgressAction} from '../../signature-installation-state/signature-installation-state.actions';

@Component({
  selector: 'app-current-employee-form',
  templateUrl: './current-employee-form.component.html',
  styleUrls: ['./current-employee-form.component.scss']
})
export class CurrentEmployeeFormComponent implements OnInit {

  form: UntypedFormGroup = new UntypedFormGroup({
    givenName: new UntypedFormControl({value: '', disabled: true}),
    familyName: new UntypedFormControl({value: '', disabled: true}),
    dynamicVariables: new FormGroup({}),
    customLinks: new FormArray([]),
    bannerDisabled: new UntypedFormControl({value: false}),
  });

  employee: Employee;
  employeeSettings: EmployeeSetting[];
  parsedSignature: string;
  settings: AdvancedSettings;

  constructor(private route: ActivatedRoute,
              private store: Store,
              private dialog: MatDialog,
              private translate: TranslateService,
              private toastr: ToastrService,
              private authService: AuthService) {
  }

  ngOnInit(): void {
    this.store.dispatch(new GetAdvancedSettingsAction());
    this.store.dispatch(new GetEmployeeSettingsAction());

    this.form.valueChanges.subscribe(values => {
      this.parsedSignature = parseFooterTemplate(this.employee?.signature, values);
    });

    this.store.select(getAdvancedSettingsSelector).subscribe((advancedSettings: AdvancedSettings) => {
      if (advancedSettings) {
        this.settings = advancedSettings;
      }
    });

    this.store.select(getEmployeeSettingsSelector).subscribe((employeeSettings: EmployeeSetting[]) => {
      this.employeeSettings = employeeSettings;
      employeeSettings.forEach((setting: EmployeeSetting) => {
        const validators = [];
        if (setting.mandatory) {
          validators.push(Validators.required);
        }
        if (['phoneNumber', 'mobileNumber'].includes(setting.name)) {
          validators.push(validateNonRequiredPhoneNumber);
        }
        this.dynamicVariables.addControl(
          setting.name,
          new FormControl({value: setting.defaultValue, disabled: !setting.overridable}, validators)
        );
      });

      this.store.dispatch(new GetCurrentUserAction());
      this.store.select(getCurrentEmployeeSelector).subscribe((employee: Employee) => {
        if (employee) {
          this.employee = employee;
          this.fillForm(employee);
          this.parsedSignature = parseFooterTemplate(this.employee.signature, employee);
          this.authService.changeAvatarUrl(employee.thumbnailPhotoUrl);
        }
      });

    });
    this.form.updateValueAndValidity();
  }

  get customLinks() {
    return this.form.get('customLinks') as FormArray;
  }

  get dynamicVariables() {
    return this.form.get('dynamicVariables') as FormGroup;
  }

  addLink = (url = '', text = '') => {
    this.customLinks.push(
      new UntypedFormGroup({
        url: new UntypedFormControl(url, [Validators.required, validateUrl]),
        text: new UntypedFormControl(text, Validators.required),
      })
    );
  }

  removeLink = (index: number) => this.customLinks.removeAt(index);

  fillForm = (employee: Employee) => {
    this.form.patchValue({
      givenName: employee.givenName,
      familyName: employee.familyName,
      dynamicVariables: employee.dynamicVariables,
      bannerDisabled: employee.bannerDisabled,
    });

    this.customLinks.clear();
    employee.customLinks.forEach(link => {
      this.addLink(link.url, link.text);
    });
  }

  onSubmit = () => {
    if (this.form.valid) {
      this.dialog.open(ConfirmationDialogComponent, {data: {title: this.translate.instant('employee.confirmationSaveAndInstall')}})
        .afterClosed()
        .subscribe(confirm => {
            if (confirm) {
              this.store.dispatch(new UpdateEmployeeAndInstallSignatureAction({...this.employee, ...this.form.value}));
              this.store.dispatch(new StartCheckingForInstallationInProgressAction());
            }
          }
        );
    } else {
      this.form.markAllAsTouched();
    }
  }

  copySignatureToClipboard = () => {
    navigator.clipboard.writeText(this.parsedSignature);
    this.toastr.success(this.translate.instant('employee.copySignatureSuccess'));
  }

  isPhoneNumberInput = name => ['phoneNumber', 'mobileNumber'].includes(name);

  downloadSignatureOutlook() {
    const htmlContent = this.parsedSignature;

    const [localPart, domain] = this.employee.email.split('@');

    const sanitizedLocalPart = localPart.replace(/\./g, '_');
    const sanitizedDomain = domain.replace(/\./g, '_');

    const fileName = `${sanitizedLocalPart}_${sanitizedDomain}.htm`;

    const blob = new Blob([htmlContent], { type: 'text/html' });

    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = fileName;

    downloadLink.click();
  }
}
