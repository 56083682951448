import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of, forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import {
  CHANGE_EXCLUDED_FROM_SIGNATURES,
  ChangeExcludedFromSignaturesAction,
  ChangeExcludedFromSignaturesSuccessAction,
  GET_EMPLOYEES,
  GetEmployeesAction,
  GetEmployeesFailureAction,
  GetEmployeesSuccessAction,
  SET_EMPLOYEES_PARAMS,
  SetEmployeesParamsAction,
  ChangeExcludedFromSignaturesFailureAction,
  SEND_EMPLOYEE_REQUEST,
  SendEmployeeRequestSuccessAction,
  SendEmployeeRequestFailureAction,
  SendEmployeeRequestAction,
  GET_EMPLOYEE,
  GetEmployeeAction,
  GetEmployeeSuccessAction,
  GetEmployeeFailureAction,
  UPDATE_EMPLOYEE,
  UpdateEmployeeAction,
  UpdateEmployeeSuccessAction,
  UpdateEmployeeFailureAction,
  GET_CURRENT_USER,
  GetCurrentUserSuccessAction,
  GetCurrentUserFailureAction,
  UpdateEmployeeAndInstallSignatureAction,
  UPDATE_EMPLOYEE_AND_INSTALL_SIGNATURE,
  UpdateEmployeeAndInstallSignatureSuccessAction,
  UpdateEmployeeAndInstallSignatureFailureAction,
  SYNC_GOOGLE_EMPLOYEES,
  SyncGoogleEmployeesSuccessAction,
  SyncGoogleEmployeesFailureAction,
  SYNC_GOOGLE_EMPLOYEES_SUCCESS,
  CLEAR_EMPLOYEES_PARAMS,
  GetCurrentUserAction
} from './employees.actions';
import { EmployeesService } from './employees.service';
import { PaginatedData } from '../shared/model/table';
import {
  ChangeExcludedFromSignaturesParams,
  Employee,
  EmployeeListItem,
  EmployeeListSearchParams,
  EmployeeRequest,
  ViewType
} from './employees.interface';
import { AuthService } from '../auth/auth.service';
import { Store } from '@ngrx/store';

@Injectable()
export class EmployeesEffects {

  getEmployees$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetEmployeesAction>(GET_EMPLOYEES),
      switchMap((action) => {
        const params = action.payload;
  
        let serviceCall: Observable<PaginatedData<EmployeeListItem>>;
  
        switch (params.viewType) {
          case 'EMPLOYEES':
            serviceCall = this.employeesService.getEmployees(params);
            break;
          case 'ALIASES':
            serviceCall = this.employeesService.getEmployees(params, true);
            break;
          case 'BOTH':
            serviceCall = forkJoin([
              // ! TODO: Fetching all users and aliases, performing pagination in memory.
              // This fetches all the data with limits, and pagination is done in memory.
              // This may cause performance issues when dealing with a large number of users.
              this.employeesService.getEmployees({ ...params, pager: { limit: 1000, page: 0 } }),
              this.employeesService.getEmployees({ ...params, pager: { limit: 1000, page: 0 } }, true)
            ]).pipe(
              map(([employees, aliases]) => {
                const combinedData = this.combineEmployeesAndAliases(employees.data, aliases.data);
                
                const paginatedData = this.paginateCombinedData(combinedData, params.pager);
                
                return {
                  data: paginatedData.data,
                  total: combinedData.length,
                };
              })
            );
            break;
          default:
            serviceCall = this.employeesService.getEmployees(params);
        }
  
        return serviceCall.pipe(
          map(employees => new GetEmployeesSuccessAction(employees)),
          catchError(error => of(new GetEmployeesFailureAction(error)))
        );
      })
    )
  );

  private paginateCombinedData(data: EmployeeListItem[], pager: { limit: number; page: number }): PaginatedData<EmployeeListItem> {
    const startIndex = pager.page * pager.limit;
    const endIndex = startIndex + pager.limit;

    return {
      data: data.slice(startIndex, endIndex),
      total: data.length,
    };
  }

  private combineEmployeesAndAliases(employees: EmployeeListItem[], aliases: EmployeeListItem[]): EmployeeListItem[] {
    return employees.flatMap(employee => {
      const employeeAliases = aliases.filter(alias => alias.fullName === employee.fullName);
      return [employee, ...employeeAliases];
    });
  }

  setEmployeeListParams$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(SET_EMPLOYEES_PARAMS),
    map((action: SetEmployeesParamsAction) => action.payload),
    map((payload: EmployeeListSearchParams) => new GetEmployeesAction(payload))
  ));

  clearEmployeeListParams$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(CLEAR_EMPLOYEES_PARAMS),
    map(() => new SetEmployeesParamsAction({pager: {limit: 10, page: 0}, sortBy: 'fullName', viewType: 'EMPLOYEES'}))
  ));

  changeExcludedFromSignatures$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(CHANGE_EXCLUDED_FROM_SIGNATURES),
    map((action: ChangeExcludedFromSignaturesAction) => action.payload),
    switchMap((payload: ChangeExcludedFromSignaturesParams) => {
      return this.employeesService.changeExcludedFromSignatures(payload).pipe(
        switchMap((response) => {
          return [new ChangeExcludedFromSignaturesSuccessAction(response)];
        }),
        catchError(error => of(new ChangeExcludedFromSignaturesFailureAction(error)))
      );
    })
  ));

  sendEmployeeRequest$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(SEND_EMPLOYEE_REQUEST),
    map((action: SendEmployeeRequestAction) => action.payload),
    switchMap((payload: EmployeeRequest) => {
      return this.employeesService.sendEmployeeRequest(payload).pipe(
        switchMap((response) => {
          return [new SendEmployeeRequestSuccessAction(response)];
        }),
        catchError(error => of(new SendEmployeeRequestFailureAction(error)))
      );
    })
  ));

  updateEmployee$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(UPDATE_EMPLOYEE),
    map((action: UpdateEmployeeAction) => action.payload),
    switchMap((payload: Employee) => {
      this.toastr.success(this.translate.instant('employee.saveSuccess'));
      this.router.navigate(['employees']);
      return this.employeesService.updateEmployee(payload).pipe(
        switchMap((response) => {
          return [new UpdateEmployeeSuccessAction(response)];
        }),
        catchError(error => of(new UpdateEmployeeFailureAction(error)))
      );
    })
  ));

  getEmployee$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(GET_EMPLOYEE),
    map((action: GetEmployeeAction) => action.payload),
    switchMap((payload: string) => {
      return this.employeesService.getEmployee(payload).pipe(
        switchMap((response: any) => {
          return [new GetEmployeeSuccessAction(response)];
        }),
        catchError(error => of(new GetEmployeeFailureAction(error)))
      );
    })
  ));

  getCurrentUser$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(GET_CURRENT_USER),
    switchMap(() => {
      return this.employeesService.getCurrentUser().pipe(
        switchMap((response: any) => {
          if(response.email === this.authService.appUser?.email)
          this.authService.changeAvatarUrl(response.thumbnailPhotoUrl);
          return [new GetCurrentUserSuccessAction(response)];
        }),
        catchError(error => of(new GetCurrentUserFailureAction(error)))
      );
    })
  ));

  updateEmployeeAndInstallSignature$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(UPDATE_EMPLOYEE_AND_INSTALL_SIGNATURE),
    map((action: UpdateEmployeeAndInstallSignatureAction) => action.payload),
    switchMap((payload: Employee) => {
      return this.employeesService.updateEmployeeAndInstallSignature(payload).pipe(
        switchMap((response) => {
          this.toastr.success(this.translate.instant('employee.saveAndInstallSuccess'));
          return [
            new UpdateEmployeeAndInstallSignatureSuccessAction(response),
            new GetCurrentUserAction()
          ];
        }),
        catchError(error => of(new UpdateEmployeeAndInstallSignatureFailureAction(error)))
      );
    })
  ));

  syncGoogleEmployees$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(SYNC_GOOGLE_EMPLOYEES),
    switchMap(() => {
      return this.employeesService.syncGoogleEmployees().pipe(
        switchMap(() => {
          this.toastr.success(this.translate.instant('employee.sync-googleSuccess'));
          return [new SyncGoogleEmployeesSuccessAction()];
        }),
        catchError(error => of(new SyncGoogleEmployeesFailureAction(error)))
      );
    })
  ));

  syncGoogleEmployeesSuccess$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(SYNC_GOOGLE_EMPLOYEES_SUCCESS),
    map(() => new GetEmployeesAction({pager: {limit: 10, page: 0}, sortBy: 'fullName'}))
  ));

  constructor(private actions$: Actions,
              private employeesService: EmployeesService,
              private authService: AuthService,
              private translate: TranslateService,
              private toastr: ToastrService,
              private router: Router,
              private store: Store) {
  }

}
